import { Component } from 'react';

class Result extends Component {

	handleRestart = (e) => {
		e.preventDefault();
		window.location.reload();
	};

    render() {
        const { score } = this.props;

        // const highest = Math.max(...Object.values(score));
		// const result = Object.keys(score).filter((key) => highest === score[key]).map((key)=> `<span class="winner-type">${key}</span>`).join(' or ');

        return (
            <div className="result">
				<h5>Thank you</h5>
                {/* <h5>Your personality type is</h5>
				<div dangerouslySetInnerHTML={{__html: result}}></div> */}
				<br></br>
				<br></br>
				<button
					type="button"
					className="btn btn-primary mt-2"
					onClick={this.handleRestart}
				>
					Restart
				</button>
            </div>
        );
    }
}

export default Result;