import { Component } from "react";
import { collection, addDoc } from "firebase/firestore";
import {db} from '../firebase';
import Question from "../Components/Question";
import qBank from "../Components/QuestionBank";
import Score from "../Components/Score";
import TypeForm from "../Components/TypeForm";
import Result from "../Components/Result";
import "./Test.css";

class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionBank: qBank,
            currentQuestion: 0,
            selectedOption: "",
            level2Options: [],
            score: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0
            },
            quizStart: false,
            quizEnd: false,
            type: 0,
            result: {}
        };
    }

    handleOptionChange = (e) => {
        this.setState({ selectedOption: e.target.value });
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.checkAnswer();
    };

    checkAnswer = () => {
        const { questionBank, currentQuestion, selectedOption } = this.state;
        const questionObj = questionBank[currentQuestion];

        if (selectedOption !== '') {
            if (selectedOption.indexOf('_') !== -1) {
                this.checkLevel2Answer();

            } else {
                const optionObj = questionObj.options.find((o) => ""+o.key === ""+selectedOption);

                if (optionObj.level2Options) {
                    this.setState((prevState) => ({
                        selectedOption: "",
                        level2Options: optionObj.level2Options
                    }));
                } else {
                    this.setState((prevState) => {
                        prevState.result["Q"+questionObj.id] = {"q": questionObj.question, "a": selectedOption+(optionObj.label ? ': '+optionObj.label : '')};
                        return {result: prevState.result};
                    });

                    this.setState((prevState) => {
                        if (!questionBank[currentQuestion].done) {
                            optionObj.typeScore.forEach((value, index) => {
                                prevState.score[index+1] = prevState.score[index+1] + value;
                            });
                            questionBank[currentQuestion].done = true;
                        }
                        return {score: prevState.score};
                    });

                    this.handleNextQuestion();
                }
            }
        }
    };

    checkLevel2Answer = () => {
        const { questionBank, currentQuestion, selectedOption } = this.state;
        const questionObj = questionBank[currentQuestion];
        const optionObj = questionBank[currentQuestion].options.find((o) => ""+o.key === ""+selectedOption.split("_")[0]);
        const optionObj2 = optionObj.level2Options.find((o) => ""+o.key === ""+selectedOption);

        this.setState((prevState) => {
            prevState.result["Q"+questionObj.id] = {"q": questionObj.question, "a": selectedOption+(optionObj2.label ? ': '+optionObj2.label : '')};
            return {result: prevState.result};
        });

        this.setState((prevState) => {
            if (!questionBank[currentQuestion].done) {
                optionObj2.typeScore.forEach((value, index) => {
                    prevState.score[index+1] = prevState.score[index+1] + value;
                });
                questionBank[currentQuestion].done = true;
            }
            return {score: prevState.score};
        });

        this.handleNextQuestion();
    };

    handleNextQuestion = () => {
        const { questionBank, currentQuestion, score, result, type } = this.state;
        if (currentQuestion + 1 < questionBank.length) {
            this.setState((prevState) => ({
                currentQuestion: prevState.currentQuestion + 1,
                selectedOption: "",
                level2Options: []
            }));
        } else {
            // All done send the result to Firebase
            this.saveDataToFirestore({
                type: type,
                qa: result,
                score: score
            });

            this.setState({
                quizEnd: true
            });
        }
    };

    handlePersonalitySubmit = (e) => {
        e.preventDefault();

        this.setState({
            quizStart: true
        });
        this.setState({
            type: +e.target.elements["type"].value
        });
    };

    saveDataToFirestore = async (data) => {
        try {
            await addDoc(collection(db, "PersonalityTest"), {
                testResult: data,
            });
            console.log("Data saved to DB: ", data);
        } catch (e) {
            console.error("Error saving data: ", data);
        }
    };

    render() {
        const { questionBank, currentQuestion, selectedOption, level2Options, score, quizEnd, quizStart } = this.state;

        return (
            <div className="App d-flex flex-column align-items-left justify-content-center">
                <h1 className="mb-4">Enneagram Personality Test</h1>
                {quizStart
                    ?(
                        !quizEnd
                            ?(<div className="flex-container">
                                <Question
                                    question={questionBank[currentQuestion]}
                                    selectedOption={selectedOption}
                                    level2Options={level2Options}
                                    onOptionChange={this.handleOptionChange}
                                    onSubmit={this.handleFormSubmit}
                                />
                                {/* <Score score={score}/> */}
                            </div>)
                            :(<Result score={score}/>)
                    )
                    :(<TypeForm onSubmit={this.handlePersonalitySubmit}/>)
                }
            </div>
        );
    }
}

export default Test;