import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// https://firebase.google.com/docs/web/setup#available-libraries

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCU0nPeIaNI1r5CLXFmQf7-wL8PVxgLE5E",
  authDomain: "trupersona.firebaseapp.com",
  projectId: "trupersona",
  storageBucket: "trupersona.appspot.com",
  messagingSenderId: "551360086701",
  appId: "1:551360086701:web:a2085a1a87ce3b4d9a3c24"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);