import { Component } from 'react';

class Score extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: true
        };
    }

    onOptionChange = (e) => {
        this.setState({ disabled: +e.target.value === 0 });
    };

    render() {
        const { onSubmit } = this.props;
        const { disabled } = this.state;

        return (
            <div>
                <p>What is your personality type</p>
                <form onSubmit={onSubmit} className="mt-2 mb-2">
                    <div className="form-group">
                        <select onChange={this.onOptionChange} className="form-select form-select-sm small-select" name="type">
                            <option value="0">Please select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                        </select>
                    </div>
                    {disabled ? (
                        <button type="submit" disabled className="btn btn-primary mt-2">
                            Next
                        </button>
                    ) : (
                        <button type="submit"  className="btn btn-primary mt-2">
                            Next
                        </button>
                    )}
                </form>
            </div>
        );
    }
}

export default Score;