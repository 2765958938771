import { Outlet, Link } from "react-router-dom";

const Layout = () => {
	return (
		<>
			<div className="mb-2">
				<Link className="logo" to="/"><span>Tru</span><span>Persona</span></Link>
			</div>
			<Outlet />
		</>	
	)
};

export default Layout;