import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Test from "./pages/Test";
import NoPage from "./pages/NoPage";

export default function App() {
  return (
	<BrowserRouter>
	  <Routes>
		<Route path="/" element={<Layout />}>
			<Route index element={<Home />} />
			<Route path="test" element={<Test />} />
			<Route path="*" element={<NoPage />} />
		</Route>
	  </Routes>
	</BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
