import { Component } from 'react';

class Options extends Component {
    render() {
        const { options, selectedOption, onOptionChange } = this.props;

        return (
            <div className='options'>
                {options.map((option, index) => (
                    <div key={index} className={option.label ? "form-check" : "form-check form-check-inline"}>
                        <input
                            type="radio"
                            name="option"
                            id={"option"+index}
                            value={option.key}
                            checked={""+selectedOption === ""+option.key}
                            onChange={onOptionChange}
                            className="form-check-input"
                        />
                        <label htmlFor={"option"+index} className="form-check-label">{option.label||option.key}</label>
                    </div>
                ))}
            </div>
        );
    }
}

export default Options;